(function($) {
  "use strict";
  $(document).ready(function () {init();});

  var init = function () {
    if ($('#public_messages__edit').length) {
      init_image_fields();
    }
  }

  const init_image_fields = function () {
    $('#public_message_image1,#public_message_image2,#public_message_image3').on('change', e => {
      $('#overlay').show();
      set_image_thumb(e.target);
      ImgUploader.upload(e.target);
    });

    $('.image-field-div .btn-destroy').on('click', e => {
      const $image_field_div = $(e.target).closest('.image-field-div');
      $image_field_div.find('.id-input').val('');
      $image_field_div.find('.name-input').val('');
      $image_field_div.find('.with-image img').attr('src', '');
      toggle_file_fields();
    })

    const ImgUploader = {
      upload: input => {
        const $input = $(input);
        const $image_field_div = $input.closest('.image-field-div');

        var formData = new FormData();
        formData.append('image', input.files[0]);
        formData.append('authenticity_token', $('meta[name="csrf-token"]').attr('content'));

        $.ajax({
          type: "POST",
          url: $input.data('url'),
          data: formData,
          dataType: 'json',
          processData: false,
          contentType: false
        }).done((data, textStatus, jqXHR) => {

          console.log(data);
          $image_field_div.find('.id-input').val(data.image_id)
          $image_field_div.find('.name-input').text(input.files[0].name)
          $image_field_div.find('.file-input').val('')

          toggle_file_fields();
          $('#overlay').hide();

        }).fail((jqXHR, textStatus, errorThrown) => {
          if (confirm("Error. Reload the page.\n\"" + errorThrown + "\"")) location.reload();
        });

        return;
      }
    }

    const set_image_thumb = function(input){
      const reader = new FileReader();
      const $input = $(input);
      const $image_field_div = $input.closest('.image-field-div');

      reader.onload = function(e) {
        const reader = new FileReader();
        $image_field_div.find('.with-image img:first').attr('src', e.target.result);
        if ( ! $image_field_div.hasClass('active')) {
          $image_field_div.addClass('active');
        }
      };

      reader.readAsDataURL(input.files[0]);
      return;
    }

    const toggle_file_fields = () => {
      $('input.id-input').each(i => {
        const $id_input = $('input.id-input:eq(' + i + ')');
        const $image_field_div = $id_input.closest('.image-field-div');

        if ($id_input.val()) {
          if ( ! $image_field_div.hasClass('active')) $image_field_div.addClass('active');
        } else {
          if ($image_field_div.hasClass('active')) $image_field_div.removeClass('active');
        }
      });
    }

    toggle_file_fields();
  }

})(jQuery);