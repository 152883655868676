// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

//#####################################################################################################
//# BOOTSTRAP
//#####################################################################################################

require("@popperjs/core")

import "bootstrap"

import { Tooltip, Popover } from "bootstrap"

//#####################################################################################################
//# JQUERY
//#####################################################################################################

require("jquery")

//#####################################################################################################
//# DIGITAL SOFTWARE WORKS JAVASCRIPTS
//#####################################################################################################

require("./dsw.basics")

//#####################################################################################################
//# SB-ADMIN
//#####################################################################################################

require("startbootstrap-sb-admin/src/js/scripts")
require("./bs_admin")

//#####################################################################################################
//# FONTAWESOME
//#####################################################################################################

// import("@fortawesome/fontawesome-pro/css/all");

//#####################################################################################################
//# CONTROLLERS
//#####################################################################################################

require("./delivery_routes")
require("./orders")
require("./product_categories")
require("./products")
require("./public_messages")
require("./users")
