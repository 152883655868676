(function($) {
  "use strict";
  $(document).ready(function () {init();});

  var init = function () {
    if ($('#orders__index').length) {
      init_multiple_confirmation_form();
      init_confirmed_order_csv_form();
    }

    if ($('#orders__cart').length) {
      init_price_display();
      init_order_type_radio_process();
    }

    if ($('#orders__edit').length) {
      init_admin_edit_order();
    }

    if ($('#orders__confirm').length) {
      var $new_order = $('form#new_order');
      var $buttons = $new_order.find('[type="submit"]');

      if ($new_order.length) {
        $new_order.submit(function () {
          $buttons.prop('disabled', true);
          return true;
        });
      }
    }
  }

  const init_multiple_confirmation_form = function() {
    const $form = $('form#multiple_confirmation_form');
    const $btn = $('button#multiple_confirmation_btn');
    const $select_all_checkbox = $('input#order_id_checkboxs');
    const $checkboxes = $('input.order-id-checkbox');

    $select_all_checkbox.change(function() {
      if ($(this).is(':checked')) {
        $checkboxes.prop('checked', true);
      } else {
        $checkboxes.prop('checked', false);
      }
    });

    $btn.on('click', function(event) {
      const $checkeds = $('input.order-id-checkbox:checked');

      if ($checkeds.length === 0) {
        event.preventDefault();
        alert('No check boxes are checked.');

        return false;
      }

      $form.children('[name="order_ids[]"]').remove();

      for (var i = 0, len = $checkeds.length; i < len; i += 1) {
        $form.append('<input type="hidden" name="order_ids[]" value="' + $checkeds.eq(i).data('order_id') + '">');
      }

      if (confirm('Are you sure to confirm ' + $checkeds.length + ' checked orders?')) {
        setTimeout(function() {show_overlay()});

        $form.submit();
      } else {
        return false;
      }
    });
  }

  const init_confirmed_order_csv_form = function() {
    const $confirmed_order_csv_form = $('#confirmed_order_csv_form');
    if ($confirmed_order_csv_form) {
      $confirmed_order_csv_form.submit();
    }
  }

  const init_price_display = function() {
    const $current_price = $('#current_price'),
          $products = $('form#new_order .product');
    if ($current_price.length && $products.length) {
      const $fields = $('form#new_order .product input[type="number"][name$="[quantity]"]');
      $fields.on('change paste keyup', function() {
        let total = 0;
        for (var i = 0, len = $fields.length; i < len; i += 1) {
          let $field = $fields.eq(i);
          if ($field.data('cents') != '' && $field.val() != '') {
            total += $field.data('cents') * $field.val();
          }
        }

        $current_price.text('$' + (total / 100).toFixed(2));
      });
    }
  }

  const init_order_type_radio_process = function() {
    const $radios = $('[name="order[order_type]"]');
    const $assuming_delivery_time = $('#order_assuming_delivery_time');
    const $pickup_date = $('#order_pickup_date');
    const $tr = $('#min_order_price_tr');

    const set_order_type_radio_process = function() {
      const $checked = $('[name="order[order_type]"]:checked');

      if ($checked.val() == 'late_order') {
        if ($assuming_delivery_time.prop('disabled')) {
          $assuming_delivery_time.prop('disabled', false).prop('required', true)
        }
        if ( ! $pickup_date.prop('disabled')) {
          $pickup_date.val('').prop('disabled', true).prop('required', false)
        }
        if ($tr.is(':hidden')) {
          $tr.show();
        }

      } else if ($checked.val() == 'pickup_order') {
        if ( ! $assuming_delivery_time.prop('disabled')) {
          $assuming_delivery_time.val('').prop('disabled', true).prop('required', false)
        }
        if ($pickup_date.prop('disabled')) {
          $pickup_date.prop('disabled', false).prop('required', true)
        }
        if ($tr.is(':visible')) {
          $tr.hide();
        }

      } else if ($checked.val() == 'additional_order') {
        if ( ! $assuming_delivery_time.prop('disabled')) {
          $assuming_delivery_time.val('').prop('disabled', true).prop('required', false)
        }
        if ( ! $pickup_date.prop('disabled')) {
          $pickup_date.val('').prop('disabled', true).prop('required', false)
        }
        if ($tr.is(':visible')) {
          $tr.hide();
        }

      } else {
        if ( ! $assuming_delivery_time.prop('disabled')) {
          $assuming_delivery_time.val('').prop('disabled', true).prop('required', false)
        }
        if ( ! $pickup_date.prop('disabled')) {
          $pickup_date.val('').prop('disabled', true).prop('required', false)
        }
        if ($tr.is(':hidden')) {
          $tr.show();
        }
      }
    }

    $radios.on('change', function() {
      set_order_type_radio_process();
    });

    set_order_type_radio_process();
  }

  const init_admin_edit_order = function() {
    const order_type_selector = '[name="order[order_type]"]'
    const $order_types = $(order_type_selector);

    if ($order_types.length) {
      const $delivery_date_field = $('#order_assuming_delivery_time');
      const $pickup_date_field = $('#order_pickup_date');

      const set_fields = function() {
        const $checked_order_type = $(order_type_selector + ':checked');

        if ($checked_order_type.val() == 'late_order') {
          if ($delivery_date_field.prop('disabled', true)) {
            if ($delivery_date_field.attr('prev_val')) $delivery_date_field.val($delivery_date_field.attr('prev_val'));
            $delivery_date_field.prop('disabled', false).prop('required', true);
          }

          if ($pickup_date_field.prop('disabled', false)) {
            if ($pickup_date_field.val()) $pickup_date_field.attr('prev_val', $pickup_date_field.val()).val('');
            $pickup_date_field.prop('disabled', true).prop('required', false);
          }

        } else if ($checked_order_type.val() == 'pickup_order') {
          if ($delivery_date_field.prop('disabled', false)) {
            if ($delivery_date_field.val()) $delivery_date_field.attr('prev_val', $delivery_date_field.val()).val('');
            $delivery_date_field.prop('disabled', true).prop('required', false);
          }

          if ($pickup_date_field.prop('disabled', true)) {
            if ($pickup_date_field.attr('prev_val')) $pickup_date_field.val($pickup_date_field.attr('prev_val'));
            $pickup_date_field.prop('disabled', false).prop('required', true);
          }

        } else {
          if ($delivery_date_field.prop('disabled', false)) {
            if ($delivery_date_field.val()) $delivery_date_field.attr('prev_val', $delivery_date_field.val()).val('');
            $delivery_date_field.prop('disabled', true).prop('required', false);
          }

          if ($pickup_date_field.prop('disabled', false)) {
            if ($pickup_date_field.val()) $pickup_date_field.attr('prev_val', $pickup_date_field.val()).val('');
            $pickup_date_field.prop('disabled', true).prop('required', false);
          }
        }

      }

      $order_types.on('change', function() {
        set_fields();
      });

      set_fields();
    }
  }


})(jQuery);
