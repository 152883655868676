(function($) {
  "use strict";
  $(document).ready(function () {init();});

  var init = function () {
    if ($('#users__new,#users__edit').length) {
      $('select[name="user[role]"]:first').change(function () {
        set_delivery_route_pulldown();
      });
      set_delivery_route_pulldown();
    }
  }

  var set_delivery_route_pulldown = function () {
        // delivery route pulldown
    var $sdri = $('select[name="user[delivery_route_id]"]:first'),
        // delivery route hidden input
        $idri = $('input[type="hidden"][name="user[delivery_route_id]"]:first');

    if ($('select[name="user[role]"]:first').val() == 'customer') {
      if ($idri.is(':enabled')) $idri.prop('disabled', true);
      if ($sdri.is(':disabled')) $sdri.prop('disabled', false);
    } else {
      if ($sdri.is(':enabled')) $sdri.val('').prop('disabled', true);
      if ($idri.is(':disabled')) $idri.prop('disabled', false);
    }
  }

})(jQuery);
