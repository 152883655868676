(function($) {
  "use strict";
  $(document).ready(function() {init();});

  const init = function() {
    if ($('#delivery_routes__index').length) {
      init_email_checkboxes();
    }
  }

  const init_email_checkboxes = function() {
    const $form = $('form#reminder_form');
    const $btn = $('button#reminder_btn');
    const $submit_btn = $('button#reminder_form_submit_btn');
    const $check_all = $('#check_all');
    const $email_checkboxes = $('.email-checkbox');

    $check_all.change(function() {
      if ($(this).is(':checked')) {
        $email_checkboxes.prop('checked', true);
      } else {
        $email_checkboxes.prop('checked', false);
      }
    });

    $btn.on('click', function(event) {
      const $checkeds = $('input.email-checkbox:checked');

      if ($checkeds.length === 0) {
        event.preventDefault();
        alert('No check boxes are checked.');

        return false;
      }

      $form.children('[name="delivery_route_ids[]"]').remove();

      for (var i = 0, len = $checkeds.length; i < len; i += 1) {
        $form.append('<input type="hidden" name="delivery_route_ids[]" value="' + $checkeds.eq(i).data('delivery_route_id') + '">');
      }

      $submit_btn.trigger('click');
    });
  }

})(jQuery);
