import jQuery from 'jquery'
import 'jquery-ui/ui/widgets/sortable';

(function($) {
  "use strict";
  $(document).ready(function() {init();});

  const init = function() {
    if ($('#products__index').length) {
      const fixHelper = function(e, ui) {
        ui.children().each(function() {
          $(this).width($(this).width());
        });
        return ui;
      };

      $('table#products_table > tbody.sortable').sortable({
        delay: 150,
        cursor: 'grabbing',
        placeholder: 'ui-sortable-placeholder',
        helper: fixHelper
      });

      var $input = $('form#products_sort_form input#ids[type="hidden"][name="ids"]:first');
      $('form#products_sort_form').submit(function () {
        $input.val('');
        var $trs = $('table#products_table > tbody > tr');
        $trs.each(function () {
          var separator = $input.val() == '' ? '' : ',';
          $input.val($input.val() + separator + $(this).data('id'));
        });

        return true;
      });
    }
  }

})(jQuery);
