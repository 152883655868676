(function($) {
  "use strict";
  $(document).ready(function() {init();});

  const init = function() {
    $('#page_actions .btn').each(function() {
      if ( ! $(this).hasClass('mb-1')) {
        $(this).addClass('mb-1');
      }
    });

    if ($('.table-responsive-fix-height').length) {
      init_table_responsive_fix_height();
    }
  }

  const init_table_responsive_fix_height = function() {
    const $table_responsive = $('.table-responsive.table-responsive-fix-height');
    const $main = $('main');

    $('footer').addClass('d-none');

    let timer = null;

    const fixHeight = function() {
      $table_responsive.css('height', '');
      const height = document.documentElement.clientHeight - (document.documentElement.scrollHeight - $table_responsive[0].offsetHeight);
      $table_responsive.css('height', height + 'px')
      clearInterval(timer);
      timer = null;
    }

    const setFixHeightTimer = function() {
      if (timer === null) timer = setTimeout(fixHeight, 5);
    }

    new ResizeObserver(setFixHeightTimer).observe($main[0])
    new ResizeObserver(setFixHeightTimer).observe(document.body)
    fixHeight()
  }

})(jQuery);
